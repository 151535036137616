// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */
import type * as Types from '../../../__generated-gql-types__/globalTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TestimonialsSchoolAdmissionInfoFragment = {
  __typename?: 'SchoolAdmissionInfo';
  status?: Types.SchoolAdmissionStatus | null;
  school: {
    __typename?: 'School';
    id: string;
    name: string;
    logoLink?: string | null;
  };
};

export type TestimonialsJobOfferInfoFragment = {
  __typename?: 'JobOfferInfo';
  company: {
    __typename?: 'Company';
    id?: string | null;
    name: string;
    logoLink?: string | null;
  };
  jobTitle?: { __typename?: 'JobTitle'; id: string; name: string } | null;
};

export type TestimonialsTestScoreV2Fragment = {
  __typename?: 'TestScoreV2';
  score: number;
  testName: string;
};

export type TestimonialsReviewFragment = {
  __typename?: 'Review';
  id: string;
  reviewedAt: number;
  comment?: string | null;
  outcomes?: Array<
    | {
        __typename?: 'JobOfferInfo';
        company: {
          __typename?: 'Company';
          id?: string | null;
          name: string;
          logoLink?: string | null;
        };
        jobTitle?: { __typename?: 'JobTitle'; id: string; name: string } | null;
      }
    | {
        __typename?: 'SchoolAdmissionInfo';
        status?: Types.SchoolAdmissionStatus | null;
        school: {
          __typename?: 'School';
          id: string;
          name: string;
          logoLink?: string | null;
        };
      }
    | { __typename?: 'TestScoreV2'; score: number; testName: string }
  > | null;
  coach: {
    __typename?: 'Coach';
    slug: string;
    user: {
      __typename?: 'User';
      firstName: string;
      lastName: string;
      pictureLink?: string | null;
    };
  };
  applicant?: {
    __typename?: 'Applicant';
    user: {
      __typename?: 'User';
      firstName: string;
      lastName: string;
      pictureLink?: string | null;
    };
  } | null;
};

export type TestimonialsFeaturedReviewsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type TestimonialsFeaturedReviewsQuery = {
  __typename?: 'Query';
  featuredReviews: Array<{
    __typename?: 'Review';
    id: string;
    reviewedAt: number;
    comment?: string | null;
    outcomes?: Array<
      | {
          __typename?: 'JobOfferInfo';
          company: {
            __typename?: 'Company';
            id?: string | null;
            name: string;
            logoLink?: string | null;
          };
          jobTitle?: {
            __typename?: 'JobTitle';
            id: string;
            name: string;
          } | null;
        }
      | {
          __typename?: 'SchoolAdmissionInfo';
          status?: Types.SchoolAdmissionStatus | null;
          school: {
            __typename?: 'School';
            id: string;
            name: string;
            logoLink?: string | null;
          };
        }
      | { __typename?: 'TestScoreV2'; score: number; testName: string }
    > | null;
    coach: {
      __typename?: 'Coach';
      slug: string;
      user: {
        __typename?: 'User';
        firstName: string;
        lastName: string;
        pictureLink?: string | null;
      };
    };
    applicant?: {
      __typename?: 'Applicant';
      user: {
        __typename?: 'User';
        firstName: string;
        lastName: string;
        pictureLink?: string | null;
      };
    } | null;
  }>;
};

export const TestimonialsSchoolAdmissionInfoFragmentDoc = gql`
  fragment Testimonials_SchoolAdmissionInfo on SchoolAdmissionInfo {
    school {
      id
      name
      logoLink
    }
    status
  }
`;
export const TestimonialsJobOfferInfoFragmentDoc = gql`
  fragment Testimonials_JobOfferInfo on JobOfferInfo {
    company {
      id
      name
      logoLink
    }
    jobTitle {
      id
      name
    }
  }
`;
export const TestimonialsTestScoreV2FragmentDoc = gql`
  fragment Testimonials_TestScoreV2 on TestScoreV2 {
    score
    testName
  }
`;
export const TestimonialsReviewFragmentDoc = gql`
  fragment Testimonials_Review on Review {
    id
    reviewedAt
    outcomes {
      ...Testimonials_SchoolAdmissionInfo
      ...Testimonials_JobOfferInfo
      ...Testimonials_TestScoreV2
    }
    coach {
      user {
        firstName
        lastName
        pictureLink
      }
      slug
    }
    comment
    applicant {
      user {
        firstName
        lastName
        pictureLink
      }
    }
  }
  ${TestimonialsSchoolAdmissionInfoFragmentDoc}
  ${TestimonialsJobOfferInfoFragmentDoc}
  ${TestimonialsTestScoreV2FragmentDoc}
`;
export const TestimonialsFeaturedReviewsDocument = gql`
  query Testimonials_featuredReviews {
    featuredReviews {
      ...Testimonials_Review
    }
  }
  ${TestimonialsReviewFragmentDoc}
`;

/**
 * __useTestimonialsFeaturedReviewsQuery__
 *
 * To run a query within a React component, call `useTestimonialsFeaturedReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestimonialsFeaturedReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestimonialsFeaturedReviewsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTestimonialsFeaturedReviewsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TestimonialsFeaturedReviewsQuery,
    TestimonialsFeaturedReviewsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TestimonialsFeaturedReviewsQuery,
    TestimonialsFeaturedReviewsQueryVariables
  >(TestimonialsFeaturedReviewsDocument, options);
}
export function useTestimonialsFeaturedReviewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TestimonialsFeaturedReviewsQuery,
    TestimonialsFeaturedReviewsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TestimonialsFeaturedReviewsQuery,
    TestimonialsFeaturedReviewsQueryVariables
  >(TestimonialsFeaturedReviewsDocument, options);
}
export function useTestimonialsFeaturedReviewsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    TestimonialsFeaturedReviewsQuery,
    TestimonialsFeaturedReviewsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    TestimonialsFeaturedReviewsQuery,
    TestimonialsFeaturedReviewsQueryVariables
  >(TestimonialsFeaturedReviewsDocument, options);
}
export type TestimonialsFeaturedReviewsQueryHookResult = ReturnType<
  typeof useTestimonialsFeaturedReviewsQuery
>;
export type TestimonialsFeaturedReviewsLazyQueryHookResult = ReturnType<
  typeof useTestimonialsFeaturedReviewsLazyQuery
>;
export type TestimonialsFeaturedReviewsSuspenseQueryHookResult = ReturnType<
  typeof useTestimonialsFeaturedReviewsSuspenseQuery
>;
export type TestimonialsFeaturedReviewsQueryResult = Apollo.QueryResult<
  TestimonialsFeaturedReviewsQuery,
  TestimonialsFeaturedReviewsQueryVariables
>;

import Image, { type ImageProps } from 'next/image';
import type { FC } from 'react';

export type NextJSImageProps = Omit<ImageProps, 'sizes'> & {
  sizes?: ImageSizes;
};

/**
 * Lightweight wrapper around NextJS Image component.
 * Abstracts setting \`sizes\` property.
 */
const NextJSImage: FC<NextJSImageProps> = ({ sizes, ...props }) => (
  <Image {...props} sizes={getSizesString(sizes)} />
);

interface ImageSizes {
  '2xl'?: string;
  xl?: string;
  lg?: string;
  md?: string;
  sm?: string;
  dflt: string;
}

// breakpoints align with tailwind default screen sizes (https://tailwindcss.com/docs/screens)
const IMAGE_SIZE_PREFIXES: Record<keyof ImageSizes, string> = {
  '2xl': '(min-width: 1536px) ',
  xl: '(min-width: 1280px) ',
  lg: '(min-width: 1024px) ',
  md: '(min-width: 768px) ',
  sm: '(min-width: 640px) ',
  dflt: '',
};

const getSizesString = (sizes?: ImageSizes): Optional<string> => {
  if (!sizes) {
    return undefined;
  }

  return (Object.entries(sizes) as Array<[string, string]>)
    .map(
      ([size, value]) =>
        `${IMAGE_SIZE_PREFIXES[size as keyof ImageSizes]}${convertRem(value)}`,
    )
    .join(', ');
};

const convertRem = (value: string): string => {
  if (value.includes('rem')) {
    const valueAsNum = parseFloat(value);
    if (!isNaN(valueAsNum)) {
      return `${valueAsNum * 16}px`;
    }
  }
  return value;
};

export default NextJSImage;

import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  Carousel,
  DateFormat,
  formatDate,
  formatName,
  ProfileImage,
} from '@leland-dev/leland-ui-library';
import Image from 'next/image';
import { type FC, useMemo } from 'react';
// eslint-disable-next-line import/no-unresolved
import { Autoplay } from 'swiper/modules';

import CompanyPlaceholderImage from '../../assets/images/placeholders/company.png';
import SchoolPlaceholderImage from '../../assets/images/placeholders/school.png';
import HbsOutcomeImage from '../../assets/svgs/homepage/outcome-brands/HBS.svg';
import GoldmanSachsOutcomeImage from '../../assets/svgs/homepage/outcome-brands/goldman-sachs.svg';
import LinkedInOutcomeImage from '../../assets/svgs/homepage/outcome-brands/linkedin.svg';
import McKinseyOutcomeImage from '../../assets/svgs/homepage/outcome-brands/mckinsey.svg';
import MetaOutcomeImage from '../../assets/svgs/homepage/outcome-brands/meta.svg';
import StanfordOutcomeImage from '../../assets/svgs/homepage/outcome-brands/stanford.svg';
import { getCoachProfileUrl } from '../../utils/routing';

import {
  TestimonialsFeaturedReviewsDocument,
  type TestimonialsFeaturedReviewsQuery,
  type TestimonialsFeaturedReviewsQueryVariables,
  type TestimonialsJobOfferInfoFragment,
  type TestimonialsReviewFragment,
  type TestimonialsSchoolAdmissionInfoFragment,
  type TestimonialsTestScoreV2Fragment,
} from './__generated-gql-types__/Testimonials.generated';

const Testimonial: FC<TestimonialsReviewFragment> = ({
  reviewedAt,
  outcomes,
  coach,
  comment,
  applicant,
}) => {
  const {
    schoolOutcomes,
    careerOutcomes,
    testOutcomes,
  }: {
    schoolOutcomes?: TestimonialsSchoolAdmissionInfoFragment[];
    careerOutcomes?: TestimonialsJobOfferInfoFragment[];
    testOutcomes?: Possible<TestimonialsTestScoreV2Fragment>;
  } = useMemo(() => {
    if (!outcomes) {
      return {};
    }
    const schoolOutcomes: TestimonialsSchoolAdmissionInfoFragment[] = [];
    const careerOutcomes: TestimonialsJobOfferInfoFragment[] = [];
    const testOutcomes: TestimonialsTestScoreV2Fragment[] = [];
    outcomes.forEach((outcome) => {
      if (outcome.__typename === 'SchoolAdmissionInfo') {
        schoolOutcomes.push(outcome);
      } else if (outcome.__typename === 'JobOfferInfo') {
        careerOutcomes.push(outcome);
      } else if (outcome.__typename === 'TestScoreV2') {
        testOutcomes.push(outcome);
      }
    });
    return { schoolOutcomes, careerOutcomes, testOutcomes: testOutcomes.pop() };
  }, [outcomes]);

  const outcomeSectionHeading = outcomes?.length
    ? `${
        careerOutcomes?.length
          ? `Landed a role at ${careerOutcomes[0].company.name}`
          : schoolOutcomes?.length
            ? `Accepted to ${schoolOutcomes[0].school.name}`
            : `Scored ${testOutcomes?.score} on the ${testOutcomes?.testName}`
      }`
    : null;

  return (
    <div className="flex w-80 flex-col self-stretch overflow-hidden rounded-lg border border-leland-gray-light/50 bg-leland-gray-dark/50 text-lg transition-colors duration-200 hover:border-leland-gray-extra-light">
      <div className="flex grow flex-col gap-4 p-6">
        <div className="flex items-center justify-between">
          <span className="text-leland-gray-extra-light">
            {applicant ? formatName(applicant.user) : 'Anonymous'}
          </span>
          <span className="text-leland-gray-extra-light">
            {formatDate(reviewedAt, DateFormat.SHORT_MONTH_DAY)}
          </span>
        </div>
        <p className="line-clamp-4 grow text-leland-white">
          &quot;{comment}&quot;
        </p>
        <div className="flex items-center gap-2">
          {careerOutcomes?.length ? (
            <Image
              className="size-6 rounded-full object-contain"
              src={
                careerOutcomes[0].company.logoLink ?? CompanyPlaceholderImage
              }
              alt={`${careerOutcomes[0].company.name} Logo`}
              width={24}
              height={24}
            />
          ) : schoolOutcomes?.length ? (
            <Image
              className="size-6 rounded-full object-contain"
              src={schoolOutcomes[0].school.logoLink ?? SchoolPlaceholderImage}
              alt={`${schoolOutcomes[0].school.name} Logo`}
              width={24}
              height={24}
            />
          ) : null}
          <span className="line-clamp-1 text-leland-gray-extra-light">
            {outcomeSectionHeading}
          </span>
        </div>
      </div>
      <a
        href={getCoachProfileUrl(coach.slug)}
        className="flex grow-0 items-center justify-between border-t border-leland-gray-light/50 px-4 py-3 text-leland-gray-extra-light transition-colors duration-100 hover:bg-leland-gray-dark hover:text-leland-white"
      >
        <span className="line-clamp-1">
          Coached by {formatName(coach.user)}
        </span>
        <ProfileImage className="size-6" {...coach.user} />
      </a>
    </div>
  );
};

export const getTestimonialsStaticProps = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
): Promise<TestimonialsReviewFragment[]> => {
  const { data } = await apolloClient.query<
    TestimonialsFeaturedReviewsQuery,
    TestimonialsFeaturedReviewsQueryVariables
  >({
    query: TestimonialsFeaturedReviewsDocument,
  });

  return data.featuredReviews;
};

interface TestimonialProps {
  testimonials: TestimonialsReviewFragment[];
  hideOrganizationProofs?: boolean;
  hideTopPadding?: boolean;
}

export const Testimonials: FC<TestimonialProps> = ({
  testimonials,
  hideOrganizationProofs,
  hideTopPadding,
}) => {
  const splitPoint = Math.floor(testimonials.length / 2);
  const topRow = testimonials.slice(0, splitPoint);
  const bottomRow = testimonials.slice(splitPoint);

  return (
    <div
      className={`w-full bg-leland-black pb-32 ${
        hideTopPadding ? 'pt-24' : 'pt-80'
      }`}
    >
      <section className="mx-auto flex w-full flex-col items-center justify-center">
        <h2 className="max-w-7xl px-6 text-center font-condensed text-4xl font-medium text-leland-white">
          Leland coaches are changing lives every day.
        </h2>
        <div className="mt-8 flex w-full flex-col gap-4">
          <Carousel
            swiperProps={{
              slidesPerView: 'auto',
              wrapperClass: 'transition-all duration-1000 ease-linear',
              speed: 8000,
              spaceBetween: 20,
              slidesPerGroup: 1,
              loop: true,
              autoplay: {
                delay: 0,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              },
              preventInteractionOnTransition: true,
              modules: [Autoplay],
            }}
            hideControls
            items={topRow.map((testimonial) => (
              <Testimonial key={testimonial.id} {...testimonial} />
            ))}
          />
          <Carousel
            swiperProps={{
              slidesPerView: 'auto',
              wrapperClass: 'transition-all duration-1000 ease-linear',
              speed: 8000,
              spaceBetween: 20,
              slidesPerGroup: 1,
              loop: true,
              autoplay: {
                delay: 0,
                reverseDirection: true,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              },
              preventInteractionOnTransition: true,
              modules: [Autoplay],
            }}
            hideControls
            items={bottomRow.map((testimonial) => (
              <Testimonial key={testimonial.id} {...testimonial} />
            ))}
          />
        </div>
        {hideOrganizationProofs ? null : (
          <div className="mt-28 px-6">
            <h3 className="text-center uppercase tracking-wider text-leland-gray-extra-light">
              Get into organizations like
            </h3>
            <div className="mt-11 flex flex-wrap items-center justify-center gap-x-6 gap-y-20 px-6 text-leland-white">
              <MetaOutcomeImage key="Meta" />
              <StanfordOutcomeImage key="Stanford" />
              <GoldmanSachsOutcomeImage key="Goldman Sachs" />
              <McKinseyOutcomeImage key="McKinsey" />
              <HbsOutcomeImage key="HBS" />
              <LinkedInOutcomeImage key="LinkedIn" />
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

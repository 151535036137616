import type { FC } from 'react';

import blurImage from '../../assets/images/blur.gif';

import NextJSImage, { type NextJSImageProps } from './NextJSImage';

type Props = Omit<NextJSImageProps, 'placeholder' | 'blurDataUrl'>;

const ImageWithBlur: FC<Props> = (props) => (
  <NextJSImage
    {...props}
    placeholder="blur"
    blurDataURL={typeof props.src === 'string' ? blurImage.src : undefined}
  />
);

export default ImageWithBlur;
